<template>
    <div class="case_data">
    
        <div class="case_data_over">
            <div class="case_data_dialog" ref="pdfContent">
          <div class="compny_flex">
            <img src="../../../../assets/image/case_data/Vector.png" alt="" />
            <span class="right">基本信息</span>
          </div>
          <div class="case_tabel">
            <div class="case_table_li">
              <div class="case_table_li_left">案例标题</div>
              <div class="case_table_li_right">{{ caseInfo.informationName }}</div>
            </div>
            <div class="case_table_li">
              <div class="case_table_li_left">案例图片</div>
              <div class="case_table_li_right">
                <div class="img" v-if="caseInfo.coverImages">
                  <img
                    :src="item"
                    alt=""
                    v-for="(item, index) in caseInfo.coverImages.split(',')"
                    :key="index"
                  />
                </div>
              </div>
            </div>
            <div class="case_table_li">
              <div class="case_table_li_left">所在地区</div>
              <div class="case_table_li_right">{{ caseInfo.province }}-{{ caseInfo.city }}</div>
            </div>
            <div class="case_table_li">
              <div class="left">
                <div class="case_table_li_left">案例类型</div>
                <div class="case_table_li_right">{{ caseInfo.state }}</div>
              </div>
              <div class="right">
                <div class="case_table_li_left">案例匹配度</div>
                <div class="case_table_li_right">
                  <el-progress
                    type="circle"
                   
                    :show-text="false"
                    :stroke-width="4"
                    :percentage="caseInfo.correlation"
                  ></el-progress>
                  {{ caseInfo.correlation || 0 }} %
                </div>
              </div>
            </div>
            <div class="case_table_li">
              <div class="left">
                <div class="case_table_li_left">案例时间</div>
                <div class="case_table_li_right">{{ caseInfo.issueTime }}</div>
              </div>
              <div class="right">
                <div class="case_table_li_left">推送时间</div>
                <div class="case_table_li_right">{{ caseInfo.pushTime }}</div>
              </div>
            </div>
            <div class="case_table_li">
              <div class="case_table_li_left">案例简介</div>
              <div class="case_table_li_right" v-html="caseInfo.briefIntroduction"></div>
            </div>
          </div>
  
          <div class="compny_flex">
            <img src="../../../../assets/image/case_data/tongdian.png" alt="" />
            <span class="right">客户痛点</span>
          </div>
          <div class="case_tabel">
            <div class="case_table_li">
              <div class="case_table_li_left">痛点描述</div>
              <div class="case_table_li_right" v-html="caseInfo.customerPainPoint"></div>
            </div>
          </div>
          <div class="compny_flex">
            <img src="../../../../assets/image/case_data/fangan.png" alt="" />
            <span class="right">解决方案</span>
          </div>
          <div class="case_tabel">
            <div class="case_table_li">
              <div class="case_table_li_left">方案描述</div>
              <div class="case_table_li_right" v-html="caseInfo.customerSolution"></div>
            </div>
          </div>
          <div class="compny_flex">
            <img src="../../../../assets/image/case_data/anli.png" alt="" />
            <span class="right">案例成果</span>
            <!-- <div class="download" @click="download()">
              <img src="../../../assets/image/case_data/download.png" alt="" />
              下载案例pdf
            </div> -->
          </div>
          <div class="case_tabel">
            <div class="case_table_li">
              <div class="case_table_li_left">成果描述</div>
              <div class="case_table_li_right" v-html="caseInfo.caseOutcome"></div>
            </div>
          </div>
          <div class="compny_flex">
            <img src="../../../../assets/image/case_data/xinqu.png" alt="" />
            <span class="right">案例关注</span>
          </div>
          <div class="case_tabel">
            <div class="case_table_li">
              <div class="case_table_li_left">有无关注</div>
              <div class="case_table_li_right">
                <el-radio-group v-model="caseInfo.isInterested">
                  <el-radio :label="true">
                    <img class="img_radio" src="../../../../assets/image/case_data/interest.png" />
  
                    用户关注</el-radio
                  >
                  <el-radio :label="false">
                    <img
                     class="img_radio"
                      src="../../../../assets/image/case_data/no-interest.png"
                    />
  
                    用户不关注</el-radio
                  >
                </el-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 40px">
          <el-button type="primary" @click="downloadPDF">下载PDF</el-button>
          <el-button type="primary" @click="sumbitClick">保 存</el-button>
          <!-- <el-button @click="DialogVisible = false">取 消</el-button> -->
        </div>
        </div>
     
    </div>
  </template>
  
  <script>
  import html2canvas from 'html2canvas'
  import jsPDF from 'jspdf'

  import { selectPlanPage, getPlanDetail, updatePlan } from '@/api/caseData.js'

  const defalutQueryInfo = Object.freeze({
    district: '',
    pageNum: 1,
    pageSize: 10,
    city: '',
    companyId: 0,
    informationName: '',
    isInterested: '',
    province: '',
    state: ''
  })
  export default {
    name: 'caseData',
    props: {
        caseId: {
        // 公司id
        typeof: Number
      }
    },
    data() {
      return {
        caseInfo: {}, //案例详情数据
        dataList: [], // 案例数据列表
        total: 0, // 总条数
        DialogVisible: false, // 弹框显示
        options: [
          { text: '全部', value: '' },
          { text: '本行业', value: '本行业' },
          { text: '相似行业', value: '相似行业' }
        ], // 案例类型
        options1: [
          { text: '全部', value: '' },
          { text: '用户关注', value: true },
          { text: '用户不关注', value: false }
        ], // 案例兴趣
        queryInfo: { ...defalutQueryInfo } // 初始化查询条件
      }
    },
   
    created() {
      this.search()
    },
    methods: {
      // 下载pdf
      downloadPDF() {
        const content = this.$refs.pdfContent
        html2canvas(content).then((canvas) => {
          const pdf = new jsPDF()
          const imgData = canvas.toDataURL('image/png')
          const imgProps = pdf.getImageProperties(imgData)
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
          pdf.save(`${this.caseInfo.informationName}.pdf`)
        })
      },
      // 点击打开案例成功
      download() {
        if (this.caseInfo.caseOutcomeFile) {
          window.open(this.caseInfo.caseOutcomeFile)
        } else {
          this.$message.warning('暂无案例文件！')
        }
      },
      // 点击保存
      sumbitClick() {
        updatePlan(this.caseInfo).then((result) => {
          if (result.resultCode === 200) {
            this.$message.success('保存成功')
            this.DialogVisible = false
            this.search()
          }
        })
      },
      // 点击详情
      async search() {
        const res = await getPlanDetail({ id: this.caseId })
        if (res.resultCode === 200) {
          this.caseInfo = res.data
          this.DialogVisible = true
        }
      },
     
      removeLableAndImage(html) {
        let msg = html
          .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
          .replace(/<br\/?>/gi, '\n')
          .replace(/<[^>/]+>/g, '')
          .replace(/(\n)?<\/([^>]+)>/g, '')
          .replace(/\u00a0/g, ' ')
          .replace(/&nbsp;/g, ' ')
          .replace(/<\/?(img)[^>]*>/gi, '')
          .replace(/&amp;/g, '&')
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace(/&#39;/g, "'")
          .replace(/&quot;/g, '"')
          .replace(/<\/?.+?>/g, '')
        return msg
      },
     
    }
  }
  </script>
  
  <style lang="less" scoped>
  ::v-deep .el-radio__label {
    font-size: 14px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }
  ::v-deep .el-progress {
    width: 32px !important;
    height: 32px !important;
  }
  ::v-deep .el-progress-circle{
    width: 30px !important;
    height: 30px !important;
  }
  ::v-deep .el-radio-group {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    font-size: 0;
    display: flex;
  }
  ::v-deep .el-radio__inner {
    border: 1px solid #dcdfe6;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    position: relative;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
  }
  ::v-deep .el-dialog__body {
    padding: 0px 20px 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  ::v-deep .el-radio__inner::after {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #fff;
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%) scale(0);
      transform: translate(-50%, -50%) scale(0);
      -webkit-transition: -webkit-transform .15s ease-in;
      transition: -webkit-transform .15s ease-in;
      transition: transform .15s ease-in;
      transition: transform .15s ease-in, -webkit-transform .15s ease-in;
  }
  ::v-deep .el-radio {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    outline: 0;
    font-size: 14px;
    display: flex;
    margin-right: 30px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    img {
      margin-right: 10px;
    }
  }
  .case_data_dialog {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0px;
  
    .download {
      display: flex;
      margin-left: 10px;
      align-items: center;
      box-sizing: border-box;
      padding: 6px 10px;
      border-radius: 4px;
      border: 1px solid #4e93fb;
      color: #4e93fb;
      font-size: 14px;
      font-weight: 400;
      cursor: pointer;
  
      img {
        width: 20px !important;
        height: 20px !important;
      }
    }
  
    .compny_flex {
      padding: 12px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e7e7e7;
  
      img {
        width: 30px;
        height: 30px;
      }
      .right_border {
        display: inline-block;
        width: 2px;
        height: 20px;
        background: #4e93fb;
      }
      .right {
        margin-left: 14px;
        color: #4e93fb;
        font-size: 16px;
      }
      .color_add {
        color: #448aff;
        margin-left: 10px;
      }
    }
  
    .case_tabel .case_table_li:nth-last-child(1) {
      border-bottom: 1px solid #dcdee0;
    }
  
    .case_tabel {
      width: 100%;
      overflow: hidden;
      font-size: 16px;
      margin-top: 20px;
      font-weight: 400;
      .img_radio{
        width: 20px;
        height: 20px;
      }
      .case_table_li {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        border-right: 1px solid #dcdee0;
        border-top: 1px solid #dcdee0;
  
        .left {
          width: 50%;
          display: flex;
          align-items: center;
          line-height: 80px;
          box-sizing: border-box;
        }
  
        .right {
          width: 50%;
          display: flex;
          align-items: center;
          line-height: 80px;
          box-sizing: border-box;
        }
  
        .case_table_li_left {
          width: 120px;
          background: #fafafa;
          display: flex;
          font-weight: 550;
          align-items: center;
          border-right: 1px solid #dcdee0;
          justify-content: center;
          border-left: 1px solid #dcdee0;
        }
  
        .case_table_li_right {
          flex: 1;
          display: flex;
          box-sizing: border-box;
          padding: 0 12px;
          align-items: center;
          line-height: 2em;
          min-height: 80px;
          flex-shrink: 1;
          .img {
            display: flex;
            align-items: center;
            img {
              width: 100px;
              height: 64px;
              border-radius: 8px;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
  .case_data {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: calc(100vh - 340px);
    padding: 20px 30px 10px 30px;
    box-sizing: border-box;
    background-color: #fff;
    .case_data_over{
        height: calc(100vh - 340px);
        overflow: auto;
         /* 滚动条整体部分 */
         &::-webkit-scrollbar {
          width: 3px;
          height: 9px;
        }
        /* 滚动条里面的滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.3);
          border-radius: 20px;
          transition: background-color 0.3s;
        }
    }
    .new_page {
      margin: auto 0;
      padding: 10px;
      box-sizing: border-box;
      width: 100%;
      text-align: center;
    }
  
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;
  
      .span {
        color: #393939;
        font-size: 16px;
        font-weight: 400;
      }
  
      ::v-deep .el-form--inline .el-form-item {
        margin-bottom: 0 !important;
      }
  
      .search_form {
        display: flex;
        align-items: center;
      }
  
      .search_right {
        margin-right: 20px;
        ::v-deep .el-button {
          border: 1px solid #448aff;
          color: #fff;
          border-radius: 0;
          background-color: #448aff;
        }
      }
    }
  
   
  }
  </style>
  