<!-- 客户需求 -->
<template>
  <div class="contact_person">
    <div class="row">
      <div>
        <span>共{{total}}条潜在线索</span>
      </div>
      <div class="search_right">
        <el-input
          placeholder="请输入"
          v-model="queryInfo.keys"
          class="input-with-select"
          style="width: 440px"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
      </div>
    </div>
    <div class="table">
      <div class="home_context_flex">
        <div class="box_history" v-for="(item, index) in list" :key="index">
          <div class="box_history_flex">
            <div>
              <img
               class="images_samll"
                v-if="!item.clueImage"
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/IMG-640687698Group 3227.png"
                alt=""
              />
              <img   class="images_samll" v-else :src="item.clueImage" alt="" />
            </div>
            <div class="box_history_left">
              <div class="box_history_top">
                <span class="companyName">{{ item.clueName }}</span>
              </div>
              <div class="compay_flex">
                <!-- <div
                    class="compay_flex_item"
                    v-for="(el, i) in item.labelList"
                    :key="i"
                  >
                    <span>{{ el }}</span>
                  </div> -->
                <span>{{ item.clueType }}</span>
                <span style="margin: 0px 10px">|</span>
                <span>{{ item.province }}-{{ item.city }}</span>
              </div>
              <div class="dis_box">
                <div class="compay_flexs">
                  <span>{{ item.clueOneDescription }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="right_flex">
              <div class="css_state">
                标注状态:
                <span v-if="item.markStatus == 1">有效</span>
                <span v-if="item.markStatus == 2">无效</span>
                <span v-if="item.markStatus == 3">待观察</span>
              </div>
              <div class="edit">
                <span @click="showPopup(item)">详情</span>
              </div>
              <!-- <div class="delete" @click="deteteClick">
                  <span>删除</span>
                </div> -->
            </div>
            <!-- <div class="time_css">
                <span>新增时间：</span>
                <span>{{ item.createTime }}</span>
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="潜在线索详情"
      :visible.sync="DialogVisible"
      width="50%"
      class="account_dialog"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px" :model="addform" ref="form">
        <div class="dia_name">
          <span>基本信息</span>
        </div>
        <div class="dia_flex">
          <div>
            <el-form-item label="线索名称" prop="">
              <span class="wid_wdu">{{ addform.clueName }}</span>
            </el-form-item>
            <el-form-item label="所在地区" prop="">
              <span class="wid_wdu">{{ addform.province }}-{{ addform.city }}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="线索类型" prop="">
              <span class="wid_wdu">{{ addform.clueType }}</span>
            </el-form-item>
            <el-form-item label="供应商要求" prop="">
              <span  class="he_gdus" >{{ addform.supplierRequirement }}</span>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item label="线索描述" prop="">
            <span class="he_gdu">{{ addform.clueOneDescription }}</span>
          </el-form-item>
        </div>
        <div class="dia_name">
          <span>客户信息</span>
        </div>
        <div class="dia_flex">
          <div>
            <el-form-item label="客户类型" prop="">
              <span class="wid_wdu">{{ addform.companyType }}</span>
            </el-form-item>
            <el-form-item label="对接人姓名" prop="">
              <span class="wid_wdu">{{ addform.customerContactPerson }}</span>
            </el-form-item>
            <el-form-item label="联系方式" prop="">
              <span class="wid_wdu">{{ addform.associatePhone }}</span>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="客户名称" prop="">
              <span class="wid_wdu">{{ addform.companyFullName }}</span>
            </el-form-item>
            <el-form-item label="对接人职位" prop="">
              <span  class="wid_wdu">{{ addform.associatePosition }}</span>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div style="text-align: center; margin-top: 40px">
        <el-button type="primary" @click="DialogVisible = false">确 定</el-button>
        <el-button @click="DialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { ipadFirstPage, cluelistById } from '@/api/enterpriseCustomer.js'

const defaultQueryInfo = Object.freeze({
  customerId: Number(sessionStorage.getItem('id')),
  mobile: '',
  keys: '',
  orderByParam: 0,
  orders: 0,
  pageNum: 1,
  pageSize: 10,
  orgCompanyId:""
})

export default {
  name: 'CustomerDemand',
  components: {},
  props: {
    id: {
      type: Number
    },

    userInfo: {
      type: Object
    },
    orgCompanyId:{
      type: String
    }
  },
  watch: {
    userInfo: {
      handler(val) {
        if (val) {

          this.queryInfo.customerId = val.id
          this.queryInfo.mobile = val.customerPhone
          this.search()
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      DialogVisible: false,
      addform: {},
      echo: 0, // 0是新增，1是编辑
      addDialogVisible: false, // 添加联络人弹窗
      queryInfo: { ...defaultQueryInfo }, // 联络人查询一览
      list: [], // 联络人列表
      total: 0 // 联络人总条数
    }
  },
  created() {},
  methods: {
    showPopup(item) {
      this.cluelistById(item.id)
    },
    async cluelistById(id) {
      const res = await cluelistById({ businessId: id })
      if (res.resultCode == 200) {
        let info = JSON.parse(res.data)
        
        this.addform = info.data
        this.DialogVisible = true
      }
    },
    async search() {
      this.queryInfo.orgCompanyId=this.orgCompanyId
      const res = await ipadFirstPage(this.queryInfo)
      if (res.resultCode === 200) {
        let info = JSON.parse(res.data)
   
        this.list = info.data.list
        this.total = info.data.total
      } else {
        this.$message.warning('获取联络人信息失败!')
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__header {
    padding: 20px 0px; 
    padding-bottom: 10px;
}
::v-deep .el-dialog__title {
    line-height: 24px;
    font-size: 18px;
    color: #303133;
    font-weight: 550;
    border-left: 4px solid #448aff;
    padding-left: 20px;
}
::v-deep .el-form-item__content {
    line-height: 40px;
    position: relative;
    font-size: 14px;
}
::v-deep .el-form-item__label {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 16px;
    color: #333333;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}

.contact_person {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  padding: 20px 30px 10px 30px;
  box-sizing: border-box;
  background-color: #fff;

  .table {
    height: calc(100vh - 390px);
    overflow: auto;
    .box_history {
      margin: 10px 5px;
      padding: 15px 15px 10px 15px;
      border-radius: 10px;
      border: 1px solid #ececec;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .box_history_top {
        font-size: 18px;
        font-weight: 550;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          border-radius: 4px;
        }
      }
      .dis_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .box_history_left {
        margin-left: 10px;
      }
      .compay_flexs {
        width: 600px;
        overflow: hidden; /* 确保超出的内容会被裁剪 */
        white-space: nowrap; /* 确保文本在一行内显示 */
        text-overflow: ellipsis; /* 超出的文本部分显示为省略号 */
        color: #333333;
        font-size: 16px;
        margin-bottom: 5px;

        .left_trips {
          margin-bottom: 2px;
        }
      }
      .compay_flex {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 15px;
        .compay_flex_item {
          color: #448aff;
          border: 1px solid #448aff;
          font-size: 10px;
          padding: 2px 3px;
          margin-right: 5px;
          border-radius: 2px;
        }
      }
      .right_flex {
        display: flex;
        align-items: center;
        justify-content: right;
        .edit {
          color: #448aff;
          margin-right: 10px;
          cursor: pointer;
          font-size: 16px;
        }
        .css_state {
          font-size: 16px;
          margin-right: 20px;
        }
        .provinceData {
          height: 20px;
        }
        .delete {
          color: #fd5469;
        }
      }
      .time_css {
        color: #a1a1a1;
        font-size: 16px;
        margin-top: 10px;
      }
      .box_history_flex {
        display: flex;
        .images_samll {
        width: 88px;
        height: 88px;
        border-radius: 4px;
      }
      }
    }
  }
}

.dialog_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}
.new_page {
  margin: 10px auto;
  width: 100%;
  text-align: center;
}
.search_right {
  // width: 560px;
  margin-right: 20px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconfont {
  margin-right: 5px;
}

.color1 {
  color: #448aff;
}
.color2 {
  color: #9b9a9a;
}
::v-deep .el-dialog__body {
    padding: 10px 20px 30px 20px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
}
::v-deep .el-form-item {
    margin-bottom: 10px;
}
.dia_name{
  font-size: 17px;
  font-weight: 550;
  color:  #4E93FB;
  padding: 10px 0px;
  border-bottom: 1px solid #e7e7e7;
}
.dia_flex{
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  .wid_wdu{
    display: inline-block;
    width: 300px;
    font-size: 16px;
    color: #333333;
  }

  

}
.he_gdus{
  width: 300px;
  display: inline-block;
  font-size: 16px;
  color: #333333;
    border-radius: 4px;
    padding: 0px 0px;
    
}
.he_gdu{
    display: inline-block;
    font-size: 16px;
    color: #333333;
    border-radius: 4px;
    padding: 0px 5px;
  }
   /* 滚动条整体部分 */
   &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
</style>