import { render, staticRenderFns } from "./caseinfo.vue?vue&type=template&id=429c0faf&scoped=true"
import script from "./caseinfo.vue?vue&type=script&lang=js"
export * from "./caseinfo.vue?vue&type=script&lang=js"
import style0 from "./caseinfo.vue?vue&type=style&index=0&id=429c0faf&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "429c0faf",
  null
  
)

export default component.exports