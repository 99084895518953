import { render, staticRenderFns } from "./serviceSuggestion.vue?vue&type=template&id=4c19ad74&scoped=true"
import script from "./serviceSuggestion.vue?vue&type=script&lang=js"
export * from "./serviceSuggestion.vue?vue&type=script&lang=js"
import style0 from "./serviceSuggestion.vue?vue&type=style&index=0&id=4c19ad74&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c19ad74",
  null
  
)

export default component.exports